#round{
    /* width: 64px;
height: 64px;
flex-shrink: 0; */
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: rgba(225, 43, 19, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
}
.cir{
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    width: 100%;
}

.cir h1{
    text-align: center;
    margin-bottom: 32px;
}
.round{
    display: flex;
    justify-content: center;
    gap: 300px;

}

.ro{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 20px;
}


@media (max-width:480px) {
    #round{
        width: 64px;
    height: 64px;
    flex-shrink: 0;
        /* width: 150px;
        height: 150px; */
        border-radius: 50%;
        background: rgba(225, 43, 19, 0.10);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cir{
        display: flex;
        flex-direction: column;
        margin-bottom: 48px;
        width: 100%;
    }
    
    .cir h1{
        text-align: center;
        margin-bottom: 32px;
    }
    .round{
        display: flex;
        justify-content: center;
        gap: 30px;
    
    }
    
    .ro{
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        text-align: center;
        align-items:center;
        gap: 20px;
    }
    
}