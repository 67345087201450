

.latesNews{
width: 100%;
display: flex;
flex-direction:column;
justify-content: center;
height: 450px;
flex-shrink: 3;
background: rgba(0, 0, 0, 0.04);
position: relative;
}

.LatesNewsName{
    position: relative;
    display: flex;
    flex-direction: row; 
    justify-content: center;
    color: #000;
font-family: Outfit;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 0px;
margin-left: 40px;
}

.LatestContent{
    position: relative;
    display: flex;
    justify-content:center;
    flex-wrap:wrap;
    align-items: center;
    gap:58px;
    left:0px;
    margin-left: 0px;
    margin-top: 50px;
    max-width: 100%; 
}


@media (min-width:1440px)
{
    .latesNews{
        width: 100%;
    }
    /* .LatesNewsName{
        margin-left: 660px;
    } */
    /* .LatestContent{
        margin-left: 220px;
    } */
}

@media (max-width: 480px){
    .latesNews{
        width: 100%;
        display: flex;
        flex-direction:column;
        justify-content: center;
        height: 750px;
        background: rgba(0, 0, 0, 0.04);
        position: relative;
    }
        
    .LatesNewsName{
            position: relative;
            display: flex;
            flex-direction: row; 
            justify-content: center;
            color: #000;
        font-family: Outfit;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 0px;
        margin-left: 0px;
        }
        
        .LatestContent{
            position: relative;
            display: flex;
            justify-content:center;
            flex-wrap:wrap;
            align-items: center;
            gap:58px;
            left:0px;
            margin-left: 0px;
            margin-top: 50px;
            max-width: 100%; 
        }
        
}