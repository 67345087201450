.contentBody1{
width: 320px;
height: 160px;
flex-shrink: 0;
border-radius: 12px;
background: #000;
background: url(Assests/LatestNews-pic.png);
}

.contentBody2{
    width: 320px;
    height: 160px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #000;
    background: url(Assests/elevate-dI-aXC7DWpQ-unsplash-1.png);
}

.contentBody3{
    width: 320px;
    height: 160px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #000;
    background: url(Assests/kendall-henderson-Pj6TgpS_Vt4-unsplash-1.png);
}


.content{
    color: #FFF;
font-family: Outfit;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 16px;
margin-left: 16px;
width: 272px;
height: 60px;
}

.newsDate{
position: absolute;
color: #FFF;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-left: 16px;
margin-top: 52px;
}

.readMore{
position: absolute;
width: 98px;
height: 20px;
justify-content: center;
align-items: flex-end;
gap: 4px;
flex-shrink: 0;
margin-top: 48px;
margin-left: 206px;
color: #E12B13;
}

.readMore  a{
position: relative;
text-decoration: none;
color: #E12B13;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 3px;
}


@media (max-width:480px){
    .contentBody1{
        width: 100%;
        height: 160px;
        flex-shrink: 0;
        border-radius: 12px;
        background: #000;
        background: url(Assests/LatestNews-pic.png) no-repeat;
        background-size: cover;
        }
        
        .contentBody2{
            width:100%;
            height: 160px;
            flex-shrink: 0;
            border-radius: 12px;
            background: #000;
            background: url(Assests/elevate-dI-aXC7DWpQ-unsplash-1.png) no-repeat;
            background-size: cover;
        }
        
        .contentBody3{
            width:100%;
            height: 160px;
            flex-shrink: 0;
            border-radius: 12px;
            background: #000;
            background: url(Assests/kendall-henderson-Pj6TgpS_Vt4-unsplash-1.png) no-repeat;
            background-size: cover;
        }
        
        
        .content{
            color: #FFF;
        position:relative;
        font-family: Outfit;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 16px;
        margin-left: 16px;
        width: 204px;
        height: 60px;
        }
        
        .newsDate{
        position: absolute;
        color: #FFF;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 16px;
        margin-top: 52px;
        }
        
        .readMore  a{
        position: relative;
        text-decoration: none;
        color: #E12B13;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 3px;
        }

    .readMore{
        display:none;
        /* position: absolute;
        width: 98px;
        height: 20px;
        justify-content: center;
        align-items: flex-end;
        gap: 4px;
        flex-shrink: 0;
        margin-top: 48px;
        margin-left: 206px;
        color: #E12B13; */
        }
    
}