.completeGet{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    width: 100%;
}

.galleryheader-1{
    margin-top: 20px;
    text-align: center;
    color: #000;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    overflow: hidden;
    width:100%;
}



.form-flex-1{
    margin-top: 10px;
}

.imagesli-1 ul{
    margin-top: 8px;
    margin-bottom: 20px;
    /* margin-left:150px; */
    list-style: none;
    display: flex;
}

.imagesli-1{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.imagesli-1 ul li{
    margin-left: 120px;
    margin-right: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text-1{
    color: #000;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

form{
    margin-top: 60px;
    /* margin-left: 580px; */
    font-family: Outfit;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-items: center; */
    text-align: start;

}


input,
textarea{
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    align-items: flex-start;
    text-align: start;
}

.flex-8{
    margin-top: -8px;
    margin-bottom: 30px;
    width: 30%;
    padding: 10px 20px 80px 20px;
    border: 1px solid #ccc;
    border-radius: 4px;

}
.formDiv{
    align-self: center;
}
#des{
    margin-top: 8px;
}

.label-1{
    text-align: start;
}

.flex-1{
    margin-top: 10px;
    display: flex;
    width: 400px;
    padding: 10px 274px 10px 16px;
    /* align-items: center; */
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.50);

}

.flex-2{
    margin-top: 10px;
    display: flex;
    width: 400px;
    padding: 10px 259px 10px 16px;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.50);

}

.flex-3{
    display: flex;
    width: 400px;
    padding: 10px 267px 10px 16px;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    margin-top: 10px;
}

.flex-4{
    margin-top: -9px;
    padding: 10px 75px 10px 16px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    width: 190px;
    display: flex;
    flex-direction: column;
}

.picDil{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
}


.flex-4-1{

    margin-top: 11px;
    padding: 10px 75px 10px 16px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    width: 190px;
    display: flex;
    flex-direction: column;
    
}

.flex-5{
margin-top: -7px;
display: inline-flex;
height: 40px;
padding: 10px 16px;
justify-content: center;
align-items: flex-start;
gap: 244px;
flex-shrink: 0;
border-radius: 4px;
border: 1px solid rgba(0, 0, 0, 0.50);
width: 400px;
}


#pickdt{
    margin-top:12px;
}

.flex-6{
    margin-top: -8px;
    /* display: inline-flex; */
    padding: 10px 196px 10px 16px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    width: 400px;
}

#boxes{
    margin-top: 10px;
}

.flex-7{
    margin-top: -15px;
    /* display: inline-flex; */
    padding: 10px 204px 10px 16px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    width: 400px;
}

.flex-8{
    border: 1px solid rgba(0, 0, 0, 0.50);
    width: 400px;
}
#wt{
    margin-top: 10px;
}
.form-flex-1 label{
    display: block;
    

}

button{
    display: flex;
    width: 400px;
    height: 40px;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #E12B13;
    margin-bottom: 20px;
}

#text-submit{
    color: #FFF;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    
}




@media screen and (max-width:480px){
    .imagesli-1 ul li{
        margin-left: 10px;
        margin-right: 0px;
    }
    .flex-1{
        
        height: 60px;
        width: 250px;
        padding: 0px 5px 20px 15px;
        align-items: center;
    }

    .flex-2,
    .flex-3,
    .flex-6,
    .flex-7{
        height: 60px;
        width: 250px;
        padding: 0px 5px 20px 15px;
        align-items: center;
    }
    .picDil{

        flex-direction: column;
        gap: 20px;
        margin-top: 10px;
    }
    .flex-4{
    padding: 0px 75px 20px 16px;
    height: 60px;
    width: 250px;
    }

    .flex-4-1{
        padding: 0px 75px 20px 16px;
    height: 60px;
    width: 250px;
    }
    .flex-5{
        height: 60px;
        padding: 10px 18px;
        gap: 24px;
        width: 250px;
    }

    .flex-8{
        height: 60px;
        width: 250px;
        padding: 20px 10px 80px 18px;
        align-items: center;
    }

    #text-submit{
        font-size: 10px;
    }

    

}