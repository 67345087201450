nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    color: rgb(226, 44, 44);
    top: 0;
}

nav ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

nav ul li {
   list-style: none; 
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: #000;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;

    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

nav ul li a:not(.active):hover {
}

nav ul li a :last-child{

    align-items: center;
    background-color: #e12b12;
    border: none;
    border-radius: 8px;
    display: inline-flex;
    font-size: 16px;
    font-weight: 500px;
    justify-content: center;
    line-height: normal;
    padding: 6px 12px;
    position: center;
    text-align: center;

}

nav .menu {
    display: none;
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span {
    height: 0.4rem;
    width: 100%;
    background-color: #E12B13;
    border-radius: 0.2rem;
}

.white{
    color: white;
}
.brd-btm::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: rgb(226, 44, 44);
    transition: width .3s;
}
.brd-btm:hover::after {
    width: 100%;
}


@media screen and (max-width: 480px) {
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
        align-items: flex-start;
        z-index: 100;
        position: sticky;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 100%;
        text-align: center;
       
    }

    .brd-btm:hover{
        border-bottom: none;
    }
    

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
}