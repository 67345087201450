/* .ou{
    margin-top: 46px;
    width:100%;
}
.heading{
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px; 
    margin-bottom: 10px;
    color: #000000;
    text-align: center;
    width: 100%;
}
.gargi
{color: rgba(0, 0, 0, 0.50);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;} 

.container {
    text-align: center; /* Center-align the content within the container 
}


.spancolor{
    display: inline-block;
font-family: 'Outfit';
font-style: normal;
font-weight: 400;
font-size: 16px;
text-align: center;
color: rgba(0, 0, 0, 0.6);
margin-bottom: 20px;
width: 100%;
}

.gargi img{
    align-self: center;
    width: 415px;
    height: 280px;
}

.gargi{
    display: flex;
    align-self: center;
    justify-content: center;
    width: 100%;
} */

.container{
    padding-top:10px;
    padding-left:100px;
    padding-right:100px;
    padding-bottom:10px;    
}

.container h6{
    color: #000;
font-family: Poppins;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.mainB{
    padding-bottom:30px;
    padding-top:30px;
}

.block{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
}

/* .block img{
    width:40%;
} */

.innerB{
    padding: 20px;
    /* width:60%; */
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;             /* to get title and p nearer*/
    flex-wrap: wrap;
    align-items:center;
}

.innerB h4{
    font-size:24px;
}
.innerB p{
    font-size:20px;
}


@media (max-width:480px) {

    .container{
        padding: 15px;
    }

    .container h6{
        color: #000;
        font-family: Outfit;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    
.mainB{
    padding:5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.block{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.block img{
    width: 50%;
}

.innerB{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items:center;
}

.innerB h4{
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.innerB p{
    display: none;
}
}