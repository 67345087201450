:root{
  --red-color : #E12B13;
  --dark-color :#000000;
  --white-color : #ffffff;
}

*,
*::after,
*::before{
  /* box-sizing: border-box; */
  margin: 0px;
  padding: 0px;
}

body{
  margin:0px;
  padding: 0px;
  font-family:'Times New Roman', Times, serif;
}

.wrapper{
  /* max-width: 1440px ; */
  margin: 0 auto;
}

a{
  text-decoration: none;
}
.cursor-pointer{
  cursor: pointer;
}