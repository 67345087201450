@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;400;500;600&display=swap');

*{
    margin: 0;
    padding: 0;
}


.footerContent{
    width:100%;
    display: flex;
    /* flex-direction: row; */
    flex-direction: column;
    height:432px;
    justify-content: center;
    align-items: center;
}


.footer{
    /* margin: 0;
    padding: 0; */
    width: 100%;
    height: 432px;
    flex-shrink: 0;
    background: #000;
    position: relative;
}

.Logo11{
    position:relative;
    /* height:60px; */
    height: 20%;
    width:193px;
    left: -500px;
    top: 0px;
}

/* .Logo1{
    width: 193px;
    height:60px;
} */

.footerName{
vertical-align: top;
color: #E12B13;
font-family: Outfit;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
position: absolute;
margin: 48px 344px 1044px 248px ;
}

.footerName span{
    color: #FFF;
    font-family: Outfit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
}

.footerBody{
/* position: absolute; */
position: relative;
/* width: 1075px; */
width: 100%;
/* height: 171px; */
height: 60%;
flex-shrink: 0;
background-color: black;

/* margin: 130px 130px 182px 380px ; */
margin-top:0px;
margin-left: 0px;
margin-bottom: 0px;
margin-right: 0px;

display: flex;
justify-content: center;
gap:260px;

}






.footerCompany{
    position: relative;
    color: #FFFFFF;
}

.footerCompany:hover:after
{
    color: #E12B13;
}

.footerCompany span{
color: #E12B13;
font-family: Outfit;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.72px;
position: absolute;
margin-bottom: 10px;
width: 150px;
}

.footerCompany ul{
    position: absolute;
    margin-top: 30px;
    list-style-type: none;
    width: 232px;
}

.footerCompany ul li{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

.footerCompany ul li svg{
    position: absolute;
    margin-top: -2.5px;
}
.footerCompany ul li svg:hover{
    color: #E12B13;
}
.footerCompany ul li a{
    text-decoration: none;
    color: #FFFFFF;
    
}
.footerCompany ul li a:hover{
    text-decoration: none;
    color: #E12B13;
}






.footerGetintouch{
    position: relative;
    color: #FFFFFF;
}

.footerGetintouch:hover:after
{
    color: #E12B13;
}

.footerGetintouch span{
color: #E12B13;
font-family: Outfit;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.72px;
position: absolute;
margin-bottom: 10px;
width: 150px;
}

.footerGetintouch ul{
    position: absolute;
    margin-top: 30px;
    list-style-type: none;
    width: 232px;
}

.footerGetintouch ul li{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

.footerGetintouch ul li svg{
    position: absolute;
    margin-top: -2.5px;
}
.footerGetintouch ul li svg:hover{
    color: #E12B13;
}
.footerGetintouch ul li a{
    text-decoration: none;
    color: #FFFFFF;
    
}
.footerGetintouch ul li a:hover{
    text-decoration: none;
    color: #E12B13;
}










.footerCon{
    position: relative;
    color: #FFFFFF;
}

.footerCon:hover:after
{
    color: #E12B13;
}

.footerCon span{
color: #E12B13;
font-family: Outfit;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.72px;
position: absolute;
margin-bottom: 10px;
width: 150px;
}

.footerCon ul{
    position: absolute;
    margin-top: 30px;
    list-style-type: none;
    width: 232px;
}

.footerCon ul li{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

.footerCon ul li svg{
    position: absolute;
    margin-top: -2.5px;
}
.footerCon ul li svg:hover{
    color: #E12B13;
}
.footerCon ul li a{
    text-decoration: none;
    color: #FFFFFF;
    
}
.footerCon ul li a:hover{
    text-decoration: none;
    color: #E12B13;
}














.footerIcon{
    margin-right: 30px;
}


.footerLine{
    /* position: absolute; */
    position: relative;
    /* margin-top: 348px; */
    margin-top: 0px;
    /*margin-left: 182px;*/
    /* width: 1076px; */
    width: 80%;
    /* height: 1px; */
    height: 0.25%;
    background: rgba(255, 255, 255, 0.32);
}

.copyright{
position: absolute;
color: #FFF;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.64px;
/*margin-left: 632px;*/
margin-top: 380px;
}

#socialMedia ul li a{
    margin-left: 30px;
}
.keeptoDate{
    color: #FFF;
    display: flex;
    flex-direction: column;
    gap: 23px;
} 

.keeptoDate span{
    color: #E12B13;
font-family: Outfit;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.72px;
}

.keeptoDate button{
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 113px;
    height: 32px;
    border: none;
    background: #E12B13;
} 

.inside{
    display: flex;
    gap: 3px;
}

#insideName{
width: 190px;
height: 32px;
}

.footerLogo{
    display: flex;
    gap:24px;
}

.circle{
    padding: 8px;
justify-content: center;
align-items: center;
border-radius: 261px;
background: #FFF;
height: 40px;
width: 40px;

}

@media (min-width:1440px) {
    .footer{
        width: 100%;
    }
}



































@media (max-width: 480px){

    

.footer{
    /* margin: 0;
    padding: 0; */
    width: 100%;
    height:1200px;
    display: flex;
    /* flex-direction: column; */
    flex-shrink: 0;
    background: #000;
    position: relative;
}

.footerContent{
    width:100%;
    height: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}


.Logo11{
    position:relative;
    height: 10%;
    left: 0px;
    top: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Logo1{
    height: 60px;
    width: 193px;
}

.footerName{
vertical-align: top;
color: #E12B13;
font-family: Outfit;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
position: absolute;
margin: 48px 344px 1044px 248px ;
}

.footerName span{
    color: #FFF;
    font-family: Outfit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
}

.footerBody{
position: relative;
width: 100%;
height: 70%;
flex-shrink: 0;
background-color: black;
margin:0px ;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
gap:30px;

}



.footerCompany{
    position: relative;
    color: #FFFFFF;
    width:100%;
    display: flex;
    justify-content: center;
    /* height: 100px; */
    height:184px;
}

.footerCompany:hover:after
{
    color: #E12B13;
}

.footerCompany span{
color: #E12B13;
font-family: Outfit;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.72px;
position: absolute;
margin-bottom: 10px;
width: 150px;
}

.footerCompany ul{
    position: absolute;
    margin-top: 30px;
    list-style-type: none;
    width: 150px;
}

.footerCompany ul li{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

.footerCompany ul li svg{
    position: absolute;
    margin-top: -2.5px;
}
.footerCompany ul li svg:hover{
    color: #E12B13;
}
.footerCompany ul li a{
    text-decoration: none;
    color: #FFFFFF;
    
}
.footerCompany ul li a:hover{
    text-decoration: none;
    color: #E12B13;
}






.footerGetintouch{
    position: relative;
    color: #FFFFFF;
    width:100%;
    display: flex;
    justify-content: center;
    height: 100px;
}

.footerGetintouch:hover:after
{
    color: #E12B13;
}

.footerGetintouch span{
color: #E12B13;
font-family: Outfit;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.72px;
position: absolute;
margin-bottom: 10px;
width: 150px;
}

.footerGetintouch ul{
    position: absolute;
    margin-top: 30px;
    list-style-type: none;
    width: 150px;
}

.footerGetintouch ul li{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

.footerGetintouch ul li svg{
    position: absolute;
    margin-top: -2.5px;
}
.footerGetintouch ul li svg:hover{
    color: #E12B13;
}
.footerGetintouch ul li a{
    text-decoration: none;
    color: #FFFFFF;
    
}
.footerGetintouch ul li a:hover{
    text-decoration: none;
    color: #E12B13;
}











.footerCon{
    position: relative;
    color: #FFFFFF;
    width:100%;
    display: flex;
    justify-content: center;
    height: 250px;
}

.footerCon:hover:after
{
    color: #E12B13;
}

.footerCon span{
color: #E12B13;
font-family: Outfit;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.72px;
position: absolute;
margin-bottom: 10px;
width: 150px;
}

.footerCon ul{
    position: absolute;
    margin-top: 30px;
    list-style-type: none;
    width: 150px;
}

.footerCon ul li{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

.footerCon ul li svg{
    position: absolute;
    margin-top: -2.5px;
}
.footerCon ul li svg:hover{
    color: #E12B13;
}
.footerCon ul li a{
    text-decoration: none;
    color: #FFFFFF;
    
}
.footerCon ul li a:hover{
    text-decoration: none;
    color: #E12B13;
}











.footerIcon{
    margin-right: 30px;
}


.footerLine{
    position: relative;
    margin-top:20px;
    /*margin-left: 182px;*/
    width: 80%;
    height: 1px;
    background: rgba(255, 255, 255, 0.32);
}

.copyright{
position: relative;
color: #FFF;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.64px;
/* margin-left: 130px; */
/* margin-top: 1270px; */
margin-top: 20px;
/* margin-bottom: 20px; */
margin-left: 0px;
}

#socialMedia ul li a{
    margin-left: 30px;
}
.keeptoDate{
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 23px;
} 

.keeptoDate span{
    color: #E12B13;
font-family: Outfit;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.72px;
}

.keeptoDate button{
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 113px;
    height: 32px;
    border: none;
    background: #E12B13;
} 
/* 
.keeptoDate button:hover{
    color: #E12B13;
    background-color: #fff;
} */


.inside{
    display: flex;
    gap: 3px;
    flex-direction: column;
    align-items: center;
}

#insideName{
width: 190px;
height: 32px;
}

.footerLogo{
    display: flex;
    gap:24px;
}

.circle{
    padding: 8px;
justify-content: center;
align-items: center;
border-radius: 261px;
background: #FFF;
height: 40px;
width: 40px;

}

}