/* @import url(https://db.onlinewebfonts.com/c/03064356c75fa5724311add92fe92b0f?family=Gilroy-Black); */
.home{
    /* background-image: url('./Assests/lorry.png'); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 1770x;
    height: 762px; 
    gap: 25px; 
}

.home button{
    display: flex;
padding: 9px 18px;
justify-content: center;
align-items: center;
gap: 10px;
color: #FFF;
font-family: Outfit;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
border-radius: 8px;
background: #E12B13;
border: none;
width: auto;
position: relative;
bottom: 500px;
margin-top: 0px;
align-items: center;
}

.homeHeading{
    color: #FFF;
font-family: Gilroy-Black;
font-size: 96px;
font-style: normal;
font-weight: 400;
line-height: normal;
align-self: center;
}


@media (min-width:1440px)
{
    .home{
        /* width: 100%; */
        background-size:100%;
    }
}
