.Wrap1{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.col-1{
    position: relative;
    width:10%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#E12B13;
}

/* .headBox{
    transform: rotate(270deg);
    background-color:#E12B13; 
    height: 150px;
    width: 300px;
    display:flex;
    justify-content: center;
    align-items: center;
    /* top: 0px;
    position: relative;
    left: -70px;
    display: flex;
    padding: 12px 72px;
    flex-direction: column;
    flex: 1; 
} */

.headline {
    transform: rotate(270deg);
    color: #fff;
    width: 500px;
    height: 100%;
    /* position:relative;
    top: 50px; */

    color: #FFF;
font-family: Poppins;
font-size: 64px;
font-style: normal;
font-weight: 800;
line-height: normal;
}

.boxes{
   display: flex;
   flex-direction: column;
   flex-wrap:wrap;
   padding: 50px 50px 30px 50px;
   width: 45%;
   gap: 30px;
   justify-content: center;
   align-content: center;
   /* flex: 4; */

}

.box{
    border: 1px solid rgba(0, 0, 0, 0.24);
    background: rgba(255, 255, 255, 0.20);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    border-radius: 5px;


    justify-content: center;
    align-content: center;
    width: 450px;
    height: 90px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.services-list{
    color: #E12B13;
    font-weight: bold;
}

.flex-container{
    display:flex;
}

/* .aboutus{
    /* width: 1440px; */

.aboutUsText{
    display: inline-flex;
    flex-direction: column;
    width: 624px;
    justify-content: center;
}
.aboutUsText h2{
    position: relative;
    margin-left: 226px;
    margin-bottom: 60px;
}
.button1{
    position: relative;
    margin-left: 230px;
    margin-top: 20px;
}
.aboutUsText p{
    height: 182px;
    color: #000;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 25.888px; /* 161.8% */
width: 624px;
}

.aboutUsText span{
color: #000;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 25.888px;
}


.imagedecor1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    width: 45%;
    /* flex: 6; */
}


.imagedecor1 img{
    width:100%;
    position: relative;
    margin-top: 50px;
}


.button2{
    background-color: #E12B13;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
    top:0px;
    /* top:250px;
    right:250px; */
    border-color: #E12B13;
    border-width: 3px;
    border-style: solid;
    border-radius: 8px;
    width:32px;
    height:20px;
    padding: 20px 50px 20px 50px;
    z-index: 1;
}
.button2:hover{
    color: #E12B13;
    background-color: #fff;
}
.text{
    color: #fff;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.text:hover{
    color: #E12B13;
}

.status{
    position: relative;
    top:70px;
    display: flex;
    flex-direction: column;
    width: 1440px;
}

.Heading{
    top:20px;
    position: absolute;
    width:100%;
    text-align:center ;
    color: #FFF;
    font-family: Outfit;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-self: center;
    /* background-color: red; */
}

.logos ul{
    list-style: none;
    display: flex;
    gap: 180px;
    /* left:120px; */
    justify-content: center;
}

.logos{
    position: relative;
    top:-220px;
    /* left:180px; */
}

@media (min-width:1440px)
  {
    #aboutus{
        width: 100%;
    }
    .wrap{
        width: 100%;
    }
    .contentcreater{
        width: 100%;
    }
    .status{
        width: 100%;
    }
}

@media (max-width:480px){
    .Wrap1{
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .col-1{
        position: relative;
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color:#E12B13;
    }

    .headbox{
        display:none;
    }
    
    .headline {
        transform: rotate(0deg);
        color: #fff;
        width: 100%;
        height: 100%;
        /* position:relative;
        top: 50px; */
    
        color: #FFF;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    }
    
    .boxes{
       display: flex;
       flex-direction: column;
       flex-wrap:wrap;
       padding: 50px 0px 30px 0px;
       width: 100%;
       gap: 30px;
       justify-content: center;
       align-content: center;
       /* flex: 4; */
    
    }
    
    .box{
        border: 1px solid rgba(0, 0, 0, 0.24);
        background: rgba(255, 255, 255, 0.20);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        border-radius: 0px;
        text-align: center;
        justify-content: center;
        align-content: center;
        /* width: 360px; */
        width: 250px;
        height: 90px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }
    
    .services-list{
        /* color: black; */
        color: #E12B13;
        font-weight: bold;
    }
    
    .flex-container{
        display:flex;
    }
    
    /* .aboutus{
        /* width: 1440px; */
    
    .aboutUsText{
        display: inline-flex;
        flex-direction: column;
        width: 624px;
        justify-content: center;
    }
    .aboutUsText h2{
        position: relative;
        margin-left: 226px;
        margin-bottom: 60px;
    }
    .button1{
        position: relative;
        margin-left: 230px;
        margin-top: 20px;
    }
    .aboutUsText p{
        height: 182px;
        color: #000;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.888px; /* 161.8% */
    width: 624px;
    }
    
    .aboutUsText span{
    color: #000;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.888px;
    }
    
    
    .imagedecor1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;
        width: 100%;
        padding-bottom: 30px;
        /* flex: 6; */
    }
    
    
    .imagedecor1 img{
        width:100%;
        position: relative;
        margin-top: 50px;
    }
    
    
    .button2{
        background-color: #E12B13;
        display: inline-flex;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        position: relative;
        top:0px;
        /* top:250px;
        right:250px; */
        border-color: #E12B13;
        border-width: 3px;
        border-style: solid;
        border-radius: 8px;
        width:32px;
        height:20px;
        padding: 20px 50px 20px 50px;
        z-index: 1;
    }
    .button2:hover{
        color: #fff;
        background-color: #E12B13;
    }
    .text{
        color: #fff;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .text:hover{
        color: aliceblue;
    }
    
    .status{
        position: relative;
        top:70px;
        display: flex;
        flex-direction: column;
        width: 1440px;
    }
    
    .Heading{
        top:20px;
        position: absolute;
        width:100%;
        text-align:center ;
        color: #FFF;
        font-family: Outfit;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        align-self: center;
        /* background-color: red; */
    }
    
    .logos ul{
        list-style: none;
        display: flex;
        gap: 180px;
        /* left:120px; */
        justify-content: center;
    }
    
    .logos{
        position: relative;
        top:-220px;
        /* left:180px; */
    }
    
    
}

