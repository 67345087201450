.App{
    display: flex;
    flex-direction: column;
}
.ContactFAQ{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap : 20px;
    margin-bottom: 20px;
}