.text-logo-status{
    top:80px;
    color: #FFF;
    color: #FFF;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.container-status-logos{
    display: flex;
    flex-direction: column;
}

.text-logo-status{
    position: relative;
    left:10px;
    top:20px;
}

.count-status1{
    color: #fff;
    font-family: Outfit;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    left:30px;
    top:5px;
}
.status{
    position: relative;
    top:0px;               /*-----------------------------------------------------------*/
    display: flex;
    flex-direction: column;
    width: 100%;
    height:311px;
}

.Heading{
    top:20px;
    left:0px;
    position: absolute;
    color: #FFF;
    font-family: Outfit;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-self: center;
    /* background-color: red; */
}

.img-container img{
    display: flex;
    flex-direction: column;
    /* max-width:  100%; */
    /* height: auto; */
    width:100%;
    height:360px;
}

.logos ul{
    list-style: none;
    display: flex;
    gap: 180px;
    /* left:120px; */
    justify-content: center;
}
.logos{
    position: relative;
    top: -200px;
    /* left:180px; */
}

@media (min-width:1440px)
{
    #aboutus{
        width: 100%;
    }
    .wrap{
        width: 100%;
    }
    .contentcreater{
        width: 100%;
    }
    .status{
        width: 100%;
    }
}


@media (min-width: 1440px) {
    .section2 {
      width: 100%;
      height: auto;
      display: block;
      background-image: url('./Assests/container.png');
    }
}


@media (max-width:480px){
    .text-logo-status{
        top:80px;
        color: #FFF;
        color: #FFF;
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .container-status-logos{
        display: flex;
        flex-direction: column;
    }
    
    .text-logo-status{
        position: relative;
        left:10px;
        top:20px;
    }
    
    .count-status1{
        color: #fff;
        font-family: Outfit;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        position: relative;
        left:30px;
        top:5px;
    }
    .status{
        position: relative;
        top:0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height:900px;
    }
    
    .Heading{
        top:20px;
        left:0px;
        position: absolute;
        color: #FFF;
        font-family: Outfit;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        align-self: center;
        /* background-color: red; */
    }
    
    .img-container img{
        display: flex;
        flex-direction: column;
        /* width: auto; */
        height: 900px;
        object-fit: cover;    
    }
    
    .logos ul{                                    /* or className='listlogo' */
        list-style: none;
        display: flex;
        flex-direction: row;
        gap: 20px;
        height:770px;
        width:120px;
        /* left:120px; */
        justify-content: center;
        flex-wrap:wrap;
        align-items: center;
    }
    .logos{
        position: relative;
        width: 100%;
        top:-800px;
        /* left:180px;     */
        justify-content: center;
        display: flex;
    }
}