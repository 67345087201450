* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.headerFaq{
	font-style: outfit;
	display: flex;
    justify-content: center;
	color: #000;
	font-size: 32px;
	font-weight: 700;
}
.headerDesc{
	font-style: outfit;
	display: flex;
	justify-content: center;
	color:#00000099;
    font-size:16px;
}


.faqs {
	width: 100%;
	max-width: 768px;
	margin: 0 auto;
}

.faqs .faq {
	margin: 15px;
	padding: 15px;
	/* background-color: #FFF; */
	background-color: transparent;
	/* border-radius: 8px; */
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.faqs .faq .faq-question {
	position: relative;
	font-size: 20px;
	padding-right: 80px;

	transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-50%);
	width: 30px;
	height: 30px;

	background-image: url('./arrow.svg');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	transition: all 0.4s ease-out;
}

.faqs .faqopen .faq-question::after {
	content: '';
	top:50%;
	position:relative;
	display:flex;
	left:430px;
	width: 30px;
	height: 30px;
	background-image: url('./arrow.svg');
	/*background-position: center;*/
	background-size: contain;
	background-repeat: no-repeat;
	
	transition: all 0.5s ease-out;
	transform: translateY(-50%) rotate(180deg);
}

.faqs .faq .faq-question::hover {
	color: red; 
}

.faqs .faq .faq-answer {
	opacity: 0;
	max-height: 0;
	overflow-y: hidden;
	transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
	color:red;
	padding-right: 80px;
	font-size: 20px;

	margin-bottom: 15px;
}

/* .faqs .faq.open .faq-question::after {
	transform: translateY(-50%) rotate(180deg);
} */

.faqs .faq.open .faq-answer {
	max-height: 1000px;
	opacity: 1;

	padding-right:80px;
	font-size: 20px;
	color: #616161;
	margin-bottom: 10px;
}

.ContactFAQ{
    display: flex;
	justify-content: center;
	padding-top: 10px;
}

.ContactFAQ .ConUs{ 
	height: 30px;
	width: 100px;
	background: #E12B13;
	border-radius: 4px;
	gap: 10px;
}

.ContactFAQ .ConUs .p{
	display: flex;
	justify-content: center;
	padding-top: 8px;
}

.container{
	height:auto;
}