.slider{
  width: 100%;
  position: relative;
  overflow: hidden;
}

.slide{
  position: absolute;
  top: 60px;
  /* left: 0; */
  width: 100%;
  opacity: 0;
  transform: translateX(-50%);
  
  /* transition: all 0.5s ease-in-out; */
  transition: linear;
  /* animation: zoom-in-zoom-out 0.5s infinite;  */
}

/* @keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
} */






.current{
  opacity: 1;
  transform: translateX(0);
}

.img-header{

 height: 763px;
  width: 100%;
}
.homeHeading{
  z-index: 1;
  overflow: hidden;
  color: #fff;
  text-align: center;
  margin-top: -760px;
  margin-left: 60px;
font-family: 'Outfit';
font-size: 64px;
font-style: normal;
font-weight: 800;
line-height: normal;
align-self: center;
}
#content-heading{
  z-index: 1;
  width: 100%;
  align-items: center;
  overflow: hidden;
  color: #fff;
  font-family: 'Outfit';
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -700px;
  display: flex;
  text-align: center;
  max-width: 550px;
}

.button-get-started {
  
  display: flex;
  padding: 9px 18px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: #E12B13;
  border: none;
  border-color: #E12B13;
  width: auto;
  margin-top: 100px;
  align-items: center;
}

#botton-get{

  border-color: #E12B13;
  width: 40%;
  border-radius: 8px;
  margin-top: 250px;
  color: #FFF;
  font-family: 'Outfit';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#botton-get:hover{
  color: #E12B13;
  background-color: #FFF;
}

.arrow{
  z-index: 999;
  border: 2px solid #fff;
  background:transparent;
  color: #fff;
  width: 2rem;
  height: 2rem;
  top:65%;
  position: absolute;
  cursor: pointer;
}

.arrow:hover{
  background-color: #FFF;
  color: #777;
}

.next{

  right:1.5rem;
}

.prev{
  
  left:1.5rem;
}


/* @media screen and (min-width:600px) {
  .slide img{
    width: 100%;
  }
} */

@media  (max-width:480px){
  .slider{
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  
  .slide{
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    opacity: 0;
    transform: translateX(-50%);
    /* transition: all 0.5s ease-in; */
    transition: linear;
    /* animation: zoom-in-zoom-out 0.5s infinite; */
  }

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }









  
  /* .slide img{
    width: 480px;
    height: 705px;
    overflow:hidden;
  } */


  .current{
    opacity: 1;
    transform: translateX(0);
  }
  
  .img-header{
    height: 763px;
    width: 100%;
    overflow:hidden;
    object-fit: cover;
  }
  .homeHeading{
    z-index: 1;
    overflow: hidden;
    color: #fff;
    display:flex;
    flex-wrap: wrap;
    text-align: center;
    margin-top: -760px;
    margin-left: 60px;
    font-family: 'Outfit';
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    align-self: center;
  }

  #content-heading{
    z-index: 1;
    width: 100%;
    align-items: center;
    overflow: hidden;
    color: #fff;
    font-family: 'Outfit';
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -700px;
    display: flex;
    text-align: center;
    max-width: 550px;
  }
  
  .button-get-started {
    
    display: flex;
    padding: 9px 18px;
    justify-content: center;
    align-items: baseline;
    gap: 10px;
    border-radius: 8px;
    background-color: #E12B13;
    border: none;
    border-color: #E12B13;
    width: 80%;
    margin-top: 100px;
    margin-left: 35px;
  }
  
  #botton-get{
  
    border-color: #E12B13;
    background-color: #E12B13;
    display: flex;
    width: 90%;
    padding: 10px 9px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  #botton-get:hover{
    color: #E12B13;
    background-color: #FFF;
  }
  
  .arrow{
    z-index: 999;
    border: 2px solid #fff;
    background:transparent;
    color: #fff;
    width: 2rem;
    height: 2rem;
    top:65%;
    position: absolute;
    cursor: pointer;
  }
  
  .arrow:hover{
    background-color: #FFF;
    color: #777;
  }
  
  .next{
  
    right:1.5rem;
  }
  
  .prev{
    
    left:1.5rem;
  }
  
}