.Wrap2{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.col-11{
    position: relative;
    width:10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .headBox{
    transform: rotate(270deg);
    background-color:#E12B13; 
    height: 150px;
    width: 300px;
    display:flex;
    justify-content: center;
    align-items: center;
    /* top: 0px;
    position: relative;
    left: -70px;
    display: flex;
    padding: 12px 72px;
    flex-direction: column;
    flex: 1; 
} */

.headline2{
    transform: rotate(270deg);
    color: #E12B13;
    width: 400px;
    height: 100%;
    /* position:relative;
    top: 50px; */

    color: #E12B13;
font-family: Poppins;
font-size: 64px;
font-style: normal;
font-weight: 800;
line-height: normal;
}

.desc{
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px;
   width: 45%;
}

.desc p{
    color: #000;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 25.9px; /* 161.875% */
}


.imagedecor{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    width: 45%;
    /* flex: 6; */
}


.imagedecor img{
    width:100%;
    position: relative;
    margin-top: 50px;
}


.button2{
    background-color: #E12B13;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
    /* top:250px;
    right:250px; */
    border-color: #E12B13;
    border-width: 3px;
    border-style: solid;
    border-radius: 8px;
    width:32px;
    height:20px;
    padding: 20px 50px 20px 50px;
    z-index: 1;
}
.button2:hover{
    color: #fff;
    background-color: #E12B13;
}
.text{
    color: #fff;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.text:hover{
    color: aliceblue;
}

.status{
    position: relative;
    top:70px;
    display: flex;
    flex-direction: column;
    width: 1440px;
}

.Heading{
    top:20px;
    position: absolute;
    width:100%;
    text-align:center ;
    color: #FFF;
    font-family: Outfit;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-self: center;
    /* background-color: red; */
}

.logos ul{
    list-style: none;
    display: flex;
    gap: 180px;
    /* left:120px; */
    justify-content: center;
}

.logos{
    position: relative;
    top:-220px;
    /* left:180px; */
}

@media (min-width:1440px)
  {
    #aboutus{
        width: 100%;
    }
    .wrap{
        width: 100%;
    }
    .contentcreater{
        width: 100%;
    }
    .status{
        width: 100%;
    }
}

@media (max-width:480px){
    .Wrap2{
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
    }
    
    .col-11{
        position: relative;
        width:100%;
        display: flex;
        justify-content: center;

        align-items: center;
    }
    
    .headline2{
        transform: rotate(0deg);
        color: #E12B13;
        width: 100%;
        height: 100%;
        /* position:relative;
        top: 50px; */
    
        color: #E12B13;
        font-family: Poppins;
        font-size: 64px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-align: center;
    }
    
    .desc{
       display: flex;
       justify-content: center;
       align-items: center;
       width: 90%;
       padding: 20px;
    }
    
    .desc p{
        color: #000;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.9px; /* 161.875% */
    text-align: center;
    }
    
    
    .imagedecor{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
        width: 100%;
        padding: 20px;
        /* flex: 6; */
    }
    
    
    .imagedecor img{
        width:100%;
        position: relative;
        margin-top: 50px;
    }
    
    
    .button2{
        background-color: #E12B13;
        display: inline-flex;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        position: relative;
        /* top:250px;
        right:250px; */
        border-color: #E12B13;
        border-width: 3px;
        border-style: solid;
        border-radius: 8px;
        width:32px;
        height:20px;
        padding: 20px 50px 20px 50px;
        z-index: 1;
    }
    .button2:hover{
        color: #fff;
        background-color: #E12B13;
    }
    .text{
        color: #fff;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .text:hover{
        color: aliceblue;
    }
    
    .status{
        position: relative;
        top:70px;
        display: flex;
        flex-direction: column;
        width: 1440px;
    }
    
    .Heading{
        top:20px;
        position: absolute;
        width:100%;
        text-align:center ;
        color: #FFF;
        font-family: Outfit;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        align-self: center;
        /* background-color: red; */
    }
    
    .logos ul{
        list-style: none;
        display: flex;
        gap: 180px;
        /* left:120px; */
        justify-content: center;
    }
    
    .logos{
        position: relative;
        top:-220px;
        /* left:180px; */
    }
}