.list{
    list-style: none;
    width: 1080px;
}

.list li{
    color: #000;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 25.9px; /* 161.875% */
text-align: left;
}

.main-list{
text-align: center;
width: 100%;
height: 337px;
display: flex;
align-self: center;
justify-content: center;
margin-bottom: 32px;
}
/* li{
    text-align: left;
} */


