/* .container {
    display: flex; 
  }
  
  .div1 {
    border-right: 2px solid #400000;
    width: 50%;
    padding: 12px;
  }

  .div2 {
    width: 50%;
    padding: 10px;
  } */


  .container1 {
    display: flex;
    left: 50px;
    align-self: center;
    justify-content: center;
    /* height: 700px; */
    height:auto;
    max-height: 850px;
    overflow: hidden;
  }
   
    .div1 {
      border-right: 2px solid #aca1a1;
      width: 50%;
      padding: 12px;
      padding-right: 30px;
      /* height: 700px; */
      height:auto;
      max-width: 500px;
    } 
  
    .div2 {
      width: 50%;
      max-width: 500px;
      /* height: 310px; */
      height:auto;
      padding: 10px;
    }


@media (max-width:480px) {
  
  .container1 {
    display: flex;
    flex-direction: column;
    left: 50px;
    align-self: center;
    justify-content:flex-start;
    /* height: 2080px; */
    height:auto;
    max-height: 2000px;
    overflow: hidden;
  }
   
  .div1 {
      border-right: 2px solid #aca1a1;
      width: 100%;
      padding: 12px;
      padding-right: 0px;
      /* height: 1000px; */
      height:auto;
      /* height:950px; */
      overflow: hidden;
      max-width: 460px;
  } 
  
  .div2 {
      width: 100%;
      max-width: 460px;
      /* height: 1000px; */
      height:auto;
      /* height:940px; */
      overflow: hidden;
      padding: 10px;
  }
  
}