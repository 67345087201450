.heading1{
    /* position: absolute;
    width: 145px;
    height: 40px;
    left: calc(50% - 145px/2 + 0.5px);
    top: 48px; */
    
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    /* line-height: 40px; */
    margin-bottom: 10px;
    color: #000000;
    text-align: center;
    
}
.mainGal{
    width: 100%;
}
.container {
    text-align: center; /* Center-align the content within the container */
}


.spancolor{
    display: inline-block;
    /* position: absolute; */
/* width: 295px;
height: 26px;
left: 570px;
top: 96px; */
/* text-align: center; */
font-family: 'Outfit';
font-style: normal;
font-weight: 400;
font-size: 16px;
/* line-height: 26px; */
/* identical to box height, or 162% */
text-align: center;
color: rgba(0, 0, 0, 0.6);
margin-bottom: 20px;
}



.main-div{
    margin: 0 150px;
}
.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns with equal width */
    /* gap: 70px; Gap between images */
    justify-content: center; /* Center the grid horizontally */
    align-items: center;
    margin-bottom: 40px;
}

.InnerGrid{
    display: flex;
    justify-self: center;
    align-items: center;
}

.image-grid img {
    max-width: 100%;
    /* display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center; */
    height: auto;
}

/* Here are the some images of our logistics. */



/* Add a media query for mobile view */
@media (max-width: 767px) {
    .image-grid {
      grid-template-columns: 1fr; /* Change to a single column */
      gap: 20px; /* Adjust the gap between images */
    }

    .main-div{
        margin: 0px;
    }
  }