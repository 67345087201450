.container{
    padding: 40px;
}

.container h1{
    color: #000;
font-family: Poppins;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.container img{
    width: 100%;
}

.info{
    display: flex;
    text-align: left;
}

.info p{
    color: #000;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 25.9px; /* 161.875% */
}

.boxx{
    display: flex;
    height: 200px;
    padding: 20px;
    flex-direction: row;
    border: 20px red;
}

.VisionIcon{
    position: relative;
    left: 20px;
    width:10%;
}

.VisionIcon img{
    height: 40px;
    width:40px;
}

.VisionContent{
    font-family: outfit;
    width:90%;
}

.VisionContent h1 {
    display:flex;
    justify-content: flex-start;
    position: relative;
    left:0px;
}

.VisionContent p {
    display:flex;
    text-align:left;
}

.why{
    color: #E12B13;
font-family: Inter;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding:20px;
}

.team{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
    padding: 20px;
    width: 100%;
}

.teamHeading{
    color: #E12B13;
font-family: Inter;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 25.9px; /* 107.917% */
padding:50px;
}

.circle1{
    height:250px;
    width:250px;
    border-radius: 50%;
    background-color: #fff;
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.circle2{
    height:200px;
    width:200px;
    border-radius: 50%;
    background-color: rgb(124, 156, 167);
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.circle2 img{
    width:100px;
    height:100px;
}

.circle2 h5{
    color:#fff;
    font-size:18px;
    position: relative;
    top:-30px;
}


@media (max-width:480px){
    .boxx{
        height: auto;
    }

    .VisionIcon{
        position: relative;
    left: -20px;
    width:10%;
    }

   .VisionContent h1 {
        display:flex;
        justify-content: center;
        position: relative;
        left:0px;
   }

    .team{
        display:flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
        padding: 20px;

    }
}