.section1{
    overflow: hidden;
    display: flex;
    width: 100%;
    margin-top: 20px;
    height: 301px;
    padding: 71px 182px 70px 182px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(29, 153, 239, 0.00) 0%, rgba(29, 153, 239, 0.12) 98.44%);
    background-color: lightgray;
}

.items{
    position: relative;
    list-style: none;
    margin: auto;
    right: 650px;
    padding: 0px 112px 0px 112px;
    display: flex;
width: 1076px;
height: 160px;
justify-content: flex-end;
align-items: flex-start;
gap: 450px;
flex-shrink: 0;

will-change: 'transform';
animation: 5s slide infinite linear;
}

.items ul {
    right:360px;
    width: 120px;
    height: 47px;
    flex-shrink: 0;
    position: absolute;
    list-style: none;
    display: flex;
    padding: 0px 152px 50px 152px;
    
    will-change: 'transform'
}

.items ul li{
    border-radius: 50%;
    display: flex;
    width: 160px;
    height: 160px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    gap:550px;
    padding: 10px 120px 10px 130px;
    
}

.items ul li img{
    border-radius: 30%;
    flex-shrink: 0;
    background: white -10.207px -42.075px / 117.701% 256% no-repeat;
    display: flex;
    width: 160px;
    height: 160px;
    padding: 56px 20px 57px 20px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

/* .image1{
    position: relative;
    display: flex;
    width: 160px;
    height: 160px;
    padding: 52px 20px 53px 20px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
} */

@media screen and (max-width: 2560px){
    .section1{
        width:100%;
    }
    @keyframes slide {
        from {
            transform: translateX(0);
        }
       to{
        transform: translateX(-1000px);
       }
    }
}

@media (max-width:480px ){
    .section1{
    width:100%;
    padding: 71px 0px;
    }

    .items{
        width:100%;
    }
}

