.contactus-header{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
}
.contact-heading{
    margin-top: 80px;
    text-align: center;
    color: #000;
    font-family: Outfit;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.para-contact-bottom{
    margin-top: 15px;
    text-align: center;
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.9px; /* 161.875% */
    width: 1076px;
}

.red-color{
    color: #E12B13;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.9px;
}

.side-heading{
    color: #000;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: rgba(0, 0, 0, 0.04);
    margin-top: 20px;
}
.off{
    background-color: white;
}
#side-side-heading{
    margin-top: 30px;
    color: #000;
    font-family: Outfit;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 32px;
}

#content-in-box{
    margin-top: 20px;
    margin-left: 32px;
    margin-bottom: 30px;
    color: #000;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.9px; /* 161.875% */
}



@media screen and (max-width: 480px){
    .contactus-header{
        width: 100%;
        /* width:fit-content; */
        overflow-x: hidden;
    }
    .contact-heading{
        font-size: 20px;
    }
    #content-in-box{
        font-size: 10px;
        margin-top: 8px;
    }
    #content-in-box::after{
        content: "\a";
    }

    #breaked-line::before{
            content:"\a";
            white-space: pre-wrap;
        }


    #side-side-heading{
        font-size: 10px;
        font-weight: 600;
    }
    .off{
        font-size: 12px;
        margin-left: 20px;
    }

    #numbers-contact::before{
        content: "\a";
        white-space: pre-wrap;
    }
    #or-in::before{
        content: "\a";
        white-space: pre-wrap;
    }
    
    .para-contact-bottom{
        margin-top: 15px;
        text-align: center;
        color: #000;
        text-align: center;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25.9px; /* 161.875% */
        width: 100%;
    }
}